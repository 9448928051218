@use './m2-app';
@use './tokens/token-utils';
@use './style/elevation';

/// @deprecated This mixin is a no-op and is going to be removed in v21.
@mixin core() {}

// Emits the mat-app-background CSS class. This predefined class sets the
// background color and text color of an element.
@mixin app-background() {
  // TODO: Move ripple styles to be dynamically loaded instead of in core.
  // This variable is used as a fallback for the ripple element's
  // background color. However, if it isn't defined anywhere, then MSS
  // complains in its verification stage.
  html {
    --mat-sys-on-surface: initial;
  }

  // Wrapper element that provides the theme background when the
  // user's content isn't inside of a `mat-sidenav-container`.
  @at-root {
    // Note: we need to emit fallback values here to avoid errors in internal builds.
    @include token-utils.use-tokens(m2-app.$prefix, m2-app.get-token-slots()) {
      .mat-app-background {
        background-color: token-utils.slot(background-color, transparent);
        color: token-utils.slot(text-color, inherit);
      }
    }
  }
}

// Emits CSS classes for applying elevation. These classes follow the pattern
// mat-elevation-z#, where # is the elevation number you want, from 0 to 24.
// These predefined classes use the CSS box-shadow settings defined by the
// Material Design specification.
@mixin elevation-classes() {
  @at-root {
    @include token-utils.use-tokens(m2-app.$prefix, m2-app.get-token-slots()) {
      // Provides external CSS classes for each elevation value. Each CSS class is formatted as
      // `mat-elevation-z$z-value` where `$z-value` corresponds to the z-space to which the element
      // is elevated.
      @for $z-value from 0 through 24 {
        $selector: elevation.$prefix + $z-value;
        // We need the `mat-mdc-elevation-specific`, because some MDC mixins
        // come with elevation baked in and we don't have a way of removing it.
        .#{$selector}, .mat-mdc-elevation-specific.#{$selector} {
          box-shadow: token-utils.slot('elevation-shadow-level-#{$z-value}',
            none);
        }
      }
    }
  }
}
